import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';

const FormPrefix = 'timeline_question';
const TimelineQuestion = observeStorage(({ id, question, explanation }) => {
  const storage = useStorage();
  const keyForStorage = `timeline_question_${id}`;

  const getAnswersArray = () => {
    const storedData = storage.dataWithChanges?.[keyForStorage] ?? {};
    return Object.entries(storedData)
      .filter(([key]) => key.startsWith(`${FormPrefix}_${id}_`))
      .map(([_, value]) => value);
  };

  const savedAnswers = getAnswersArray();

  const [nbExtraTextAreas, setNbExtraTextAreas] = React.useState(
    Math.max(savedAnswers.length, storage.data[`nbExtraTextAreas_${id}`] ?? 1)
  );

  const { register, watch, getValues, setValue } = useForm({
    defaultValues: storage.dataWithChanges?.[keyForStorage] ?? {},
  });

  watch((data) => {
    storage.setItems({ [keyForStorage]: data });
  });

  const addTextArea = () => {
    setNbExtraTextAreas(nbExtraTextAreas + 1);
    storage.setItem(`nbExtraTextAreas_${id}`, nbExtraTextAreas + 1);
  };

  useOnResetChapter(() => {
    // reset the form
    const fieldsInForm = Object.keys(getValues());
    for (const field of fieldsInForm) {
      setValue(field, '');
    }
    storage.setItems({ [keyForStorage]: {} });

    // reset the extra rows
    setNbExtraTextAreas(1);
    storage.setItem(`nbExtraTextAreas_${id}`, 0);
  });

  return (
    <section className="content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <div className="question">
              <h4 dangerouslySetInnerHTML={{ __html: question }}></h4>
              <div
                className="text-wrapper wysiwyg"
                dangerouslySetInnerHTML={{ __html: explanation }}
              />
              <form>
                <div className="form-group questionTimeLine">
                  <div className="row">
                    <div className="col-4 col-md-4">
                      <div className="timeline-wrapper">
                        <div className="start-label">Geboorte</div>
                        <div className="timeline"></div>
                        <div className="end-label">Huidige leeftijd</div>
                      </div>
                    </div>
                    <div className="col-8 col-md-7 offset-md-1">
                      {Array.from({ length: nbExtraTextAreas }, (_, i) => (
                        <div className="input-wrapper" key={i}>
                          <span>{i + 1}.</span>
                          <textarea
                            className="form-control"
                            rows="3"
                            defaultValue={
                              storage.dataWithChanges?.[keyForStorage]?.[
                                `${FormPrefix}_${id}_${i}`
                              ] || ''
                            }
                            {...register(`${FormPrefix}_${id}_${i}`)}
                          ></textarea>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </form>
              <div className="row justify-content-end">
                <div className="col-auto">
                  <Button className="" variant="secondary" onClick={addTextArea}>
                    NIEUW TEKSTVAK
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default TimelineQuestion;
